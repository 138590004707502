import React, { useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import './DeleteAccount.css';

export default function DeleteAccount() {
    const [mobileNumber, setMobileNumber] = useState('');
    const [result, setResult] = useState(0); // 0: initial, 1: sending, 2: sent
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        // Validate mobile number (10 digits)
        if (!/^\d{10}$/.test(mobileNumber)) {
            setError('Please enter a valid 10-digit mobile number');
            return;
        }

        setResult(1); // Show sending state

        try {
            // Using emailjs.sendForm() instead of emailjs.send()
            const response = await emailjs.sendForm(
                'service_3ny04ae',
                'template_g35s8pt',
                e.target,
                'ZGSHy7-bpYi6kH0XC'
            );

            console.log('EmailJS Response:', response);

            if (response.status === 200) {
                console.log('Email sent successfully');
                setResult(2);
                setMobileNumber('');
            } else {
                throw new Error(`Email failed with status: ${response.status}`);
            }
        } catch (err) {
            console.error('Email sending failed:', err);
            setError(`Failed to submit request: ${err.message || 'Unknown error'}`);
            setResult(0);
        }
    };

    // Handle success message timing
    useEffect(() => {
        if (result === 2) {
            const buttonElement = document.getElementById("deleteSubmitButton");
            if (buttonElement) {
                buttonElement.style.backgroundColor = "#2e7d32"; // Success green
            }

            // Reset after 5 seconds
            const timer = setTimeout(() => {
                setResult(0);
                if (buttonElement) {
                    buttonElement.style.backgroundColor = "#d32f2f"; // Reset to original color
                }
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [result]);

    return (
        <div className="sbmg-delete-container">
            <div className="sbmg-delete-content">
                <h1 className="sbmg-delete-title">SBMG Account Deletion Request</h1>
                <p className="sbmg-delete-description">
                    You can enter your mobile number & submit a request to delete your account from SBMG Rajasthan app.
                </p>

                {result !== 2 ? (
                    <form onSubmit={handleSubmit} className="sbmg-delete-form">
                        <div className="sbmg-delete-form-group">
                            <label htmlFor="mobileNumber" className="sbmg-delete-label">
                                Mobile Number
                            </label>
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                className="sbmg-delete-input"
                                value={mobileNumber}
                                onChange={(e) => setMobileNumber(e.target.value)}
                                placeholder="Enter your 10-digit mobile number"
                                maxLength="10"
                                required
                            />
                        </div>

                        {error && <p className="sbmg-delete-error">{error}</p>}

                        <button 
                            type="submit" 
                            id="deleteSubmitButton"
                            className="sbmg-delete-button"
                            disabled={result === 1}
                        >
                            {result === 1 ? 'Submitting...' : 'Submit'}
                        </button>
                    </form>
                ) : (
                    <div className="sbmg-delete-success">
                        <p>You have successfully submitted the request, your account will be deleted within 7-10 days.</p>
                    </div>
                )}
            </div>
        </div>
    );
}
