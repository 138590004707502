import React from 'react';
import './SBMGPrivacy.css';

export default function SBMGPrivacy() {
    return (
        <div className="sbmg-privacy-container">
            <div className="sbmg-privacy-content">
                <h1 className="sbmg-privacy-heading">Privacy Policy for SBMG Rajasthan App</h1>
                <div className="sbmg-privacy-metadata">
                    <p className="sbmg-privacy-text">Effective Date: 22 January 2025</p>
                    <p className="sbmg-privacy-text">Last Updated: 22 January 2025</p>
                </div>

                <section className="sbmg-privacy-section">
                    <h2 className="sbmg-privacy-section-title">Introduction</h2>
                    <p className="sbmg-privacy-text">
                        SBMG Rajasthan App ("the App") is designed to manage and monitor cleanliness activities
                        across villages while ensuring user privacy and data security. This Privacy Policy explains how
                        we collect, use, store, and protect your data, as well as your rights concerning the information
                        provided to us.
                    </p>
                </section>

                <section className="sbmg-privacy-section">
                    <h2 className="sbmg-privacy-section-title">1. Information We Collect</h2>
                    <p className="sbmg-privacy-text">We collect the following types of data to ensure efficient functioning of the App:</p>
                    <div className="sbmg-privacy-subsection">
                        <h3 className="sbmg-privacy-subsection-title">1.1 Personal Information</h3>
                        <ul className="sbmg-privacy-list">
                            <li className="sbmg-privacy-list-item">Mobile number (during feedback or complaint submission)</li>
                            <li className="sbmg-privacy-list-item">District and Gram Panchayat (GP) details for reporting and tracking activities</li>
                        </ul>

                        <h3 className="sbmg-privacy-subsection-title">1.2 Location Data</h3>
                        <ul className="sbmg-privacy-list">
                            <li className="sbmg-privacy-list-item">Geo-tagged photos: Captured before and after cleaning tasks or complaints for verification purposes</li>
                            <li className="sbmg-privacy-list-item">GPS data: Used for tracking worker routes during cleanliness activities</li>
                        </ul>

                        <h3 className="sbmg-privacy-subsection-title">1.3 Activity Data</h3>
                        <ul className="sbmg-privacy-list">
                            <li className="sbmg-privacy-list-item">Tasks completed, including the number of villages cleaned, garbage collected, and roads swept</li>
                            <li className="sbmg-privacy-list-item">QR code scans, timestamps, and addresses for garbage collection verification</li>
                            <li className="sbmg-privacy-list-item">Records of complaints, their statuses (resolved or pending), and associated details</li>
                        </ul>

                        <h3 className="sbmg-privacy-subsection-title">1.4 Device Information</h3>
                        <ul className="sbmg-privacy-list">
                            <li className="sbmg-privacy-list-item">IMEI codes of devices used by workers to track assigned tasks</li>
                        </ul>
                    </div>
                </section>

                <section className="sbmg-privacy-section">
                    <h2 className="sbmg-privacy-section-title">2. How We Use Your Data</h2>
                    <p className="sbmg-privacy-text">The data collected is used for the following purposes:</p>
                    <ul className="sbmg-privacy-list">
                        <li className="sbmg-privacy-list-item">Monitoring and managing cleanliness activities in villages</li>
                        <li className="sbmg-privacy-list-item">Verifying the completion of tasks through geo-tagged images and GPS tracking</li>
                        <li className="sbmg-privacy-list-item">Responding to user feedback and resolving complaints</li>
                        <li className="sbmg-privacy-list-item">Generating operational and administrative reports for state, district, and block-level authorities</li>
                        <li className="sbmg-privacy-list-item">Ensuring transparency and accountability in waste management processes</li>
                    </ul>
                </section>

                <section className="sbmg-privacy-section">
                    <h2 className="sbmg-privacy-section-title">3. Data Sharing</h2>
                    <p className="sbmg-privacy-text">We do not sell or rent your personal information to third parties. However, your data may be shared with:</p>
                    <ul className="sbmg-privacy-list">
                        <li className="sbmg-privacy-list-item">Government Bodies: To monitor and report cleanliness operations</li>
                        <li className="sbmg-privacy-list-item">Service Providers: For server hosting, app analytics, or technical support (bound by confidentiality agreements)</li>
                    </ul>
                    <p className="sbmg-privacy-text">All shared data is used solely for the purposes outlined in this policy.</p>
                </section>

                <section className="sbmg-privacy-section">
                    <h2 className="sbmg-privacy-section-title">4. Data Storage and Security</h2>
                    <p className="sbmg-privacy-text">We take data security seriously and implement the following measures:</p>
                    <ul className="sbmg-privacy-list">
                        <li className="sbmg-privacy-list-item">Data is securely stored on protected servers with encryption protocols</li>
                        <li className="sbmg-privacy-list-item">Access to data is restricted to authorized personnel only</li>
                        <li className="sbmg-privacy-list-item">Regular audits and monitoring are conducted to prevent unauthorized access or breaches</li>
                    </ul>
                    <p className="sbmg-privacy-text">Despite these measures, no system can be completely secure. Users are encouraged to report any suspected security vulnerabilities.</p>
                </section>

                <section className="sbmg-privacy-section">
                    <h2 className="sbmg-privacy-section-title">5. Permissions Required</h2>
                    <p className="sbmg-privacy-text">The following permissions are necessary to enable the core functionalities of the App. We do not use them for any purposes beyond those explicitly stated below. Permissions are never shared or misused.</p>
                    <ul className="sbmg-privacy-list">
                        <li className="sbmg-privacy-list-item">Camera: To capture and upload geo-tagged photos of cleanliness activities and complaints</li>
                        <li className="sbmg-privacy-list-item">Location Services: To verify and track worker movements during tasks</li>
                        <li className="sbmg-privacy-list-item">Storage: To temporarily store images and complaint data before submission</li>
                    </ul>
                    <p className="sbmg-privacy-text">These permissions are used strictly for the intended purposes and are never exploited.</p>
                </section>

                <section className="sbmg-privacy-section">
                    <h2 className="sbmg-privacy-section-title">6. User Rights</h2>
                    <p className="sbmg-privacy-text">As a user of the App, you have the following rights:</p>
                    <ul className="sbmg-privacy-list">
                        <li className="sbmg-privacy-list-item">Access Your Data: View your submitted complaints, feedback, and associated records</li>
                        <li className="sbmg-privacy-list-item">Update Data: Request corrections to inaccurate or incomplete personal data</li>
                        <li className="sbmg-privacy-list-item">Delete Data: Request deletion of your personal data, subject to operational and legal requirements</li>
                        <li className="sbmg-privacy-list-item">Withdraw Consent: Revoke previously granted permissions (this may limit app functionality)</li>
                    </ul>
                    <p className="sbmg-privacy-text">Requests can be made by contacting us at the details provided below.</p>
                </section>

                <section className="sbmg-privacy-section">
                    <h2 className="sbmg-privacy-section-title">7. Third-Party Services</h2>
                    <p className="sbmg-privacy-text">The App may utilize third-party services for:</p>
                    <ul className="sbmg-privacy-list">
                        <li className="sbmg-privacy-list-item">Analytics and Monitoring: To improve app performance</li>
                        <li className="sbmg-privacy-list-item">Hosting and Infrastructure: For secure data storage and processing</li>
                    </ul>
                    <p className="sbmg-privacy-text">These third-party services are governed by their own privacy policies. We recommend reviewing them for additional information.</p>
                </section>

                <section className="sbmg-privacy-section">
                    <h2 className="sbmg-privacy-section-title">8. Data Retention</h2>
                    <p className="sbmg-privacy-text">Data will be retained only as long as necessary to fulfill the purposes outlined in this policy or as required by law. When no longer needed, data will be securely deleted or anonymized.</p>
                </section>

                <section className="sbmg-privacy-section">
                    <h2 className="sbmg-privacy-section-title">9. Updates to the Privacy Policy</h2>
                    <p className="sbmg-privacy-text">This Privacy Policy may be updated periodically to reflect changes in the App's features, operations, or legal obligations. Users will be notified of significant changes through in-app notifications or email.</p>
                </section>

                <section className="sbmg-privacy-section sbmg-privacy-contact">
                    <h2 className="sbmg-privacy-section-title">10. Contact Information</h2>
                    <p className="sbmg-privacy-text">For any questions, concerns, or to exercise your rights related to this Privacy Policy, please contact us:</p>
                    <div className="sbmg-privacy-contact-details">
                        <p className="sbmg-privacy-text"><strong>Developed By:</strong> Vysion Technology</p>
                        <p className="sbmg-privacy-text"><strong>Email:</strong> vysiontechnology@gmail.com</p>
                        <p className="sbmg-privacy-text"><strong>Phone:</strong> +91 8078693503</p>
                        <p className="sbmg-privacy-text"><strong>Office Address:</strong> Panchayatiraj Bhawan, Jaipur, 342003</p>
                    </div>
                </section>

                <div className="sbmg-privacy-footer">
                    <p className="sbmg-privacy-text">By using the SBMG Rajasthan App, you agree to the terms outlined in this Privacy Policy.</p>
                </div>
            </div>
        </div>
    );
}
